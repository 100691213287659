import { identity, sortBy } from 'lodash-es';
import { datelib } from './date-adapter';
import { Timestamp } from '@angular/fire/firestore'
import { DateOnly } from './date-only'

export const _isNewDesign = true as const;

export const NARROW_NO_BREAK_SPACE = '\u202F' as const;
export const NO_BREAK_SPACE = '\u00A0' as const;
export const THIN_SPACE = '\u2009' as const;
export const FIGURE_SPACE = '\u2007' as const;
export const WORD_JOINER = '\u2060' as const;

export const modulo = (x: number, m: number): number => ((x % m) + m) % m;
export const toDefaultNumber = (x: unknown, d: number): number =>
  Number.isNaN(Number(x)) ? d : Number(x);

export const intersection = <T>(items1: T[] = [], items2: T[] = []): T[] =>
  items1.filter((v) => items2.includes(v));

export const unique = <T>(items: T[]): T[] => Array.from(new Set(items));

export const toArray = <T>(item?: T | T[]): T[] =>
  Array.isArray(item) ? item : item ? [item] : [];

export const toUniqueArray = <T>(item?: T | T[]): T[] => [...new Set(toArray(item))];

export const toNonNullableArray = <T>(item?: T | (T|null|undefined)[]): NonNullable<T>[] =>
  toArray(item).filter((a): a is NonNullable<T> => a != null);

export const toUniqueNonNullableArray = <T>(item?: T | (T|null|undefined)[]): NonNullable<T>[] =>
  [...new Set(toNonNullableArray(item))];

export const toLowerCaseArray = (item?: string | (string|null|undefined)[]): (string|null|undefined)[] =>
  toArray(item).map((s) => s?.toLowerCase() ?? s);

export const toNonNullableLowerCaseArray = (item?: string | (string|null|undefined)[]): string[] =>
  toNonNullableArray(item).map((s) => s.toLowerCase());

export const toUniqueNonNullableLowerCaseArray = (item?: string | (string|null|undefined)[]): string[] =>
  [...new Set(toNonNullableLowerCaseArray(item))];

export const uniqueByKey = <T>(items: T[], key: keyof T): T[] => {
  const seen = new Set<T[keyof T]>();
  return items.filter((item) => !seen.has(item[key]) && seen.add(item[key]));
};

export const uniqueByKeys = <T>(items: T[], keys: (keyof T)[]): T[] => {
  if (!keys.length) return [...new Set(items)];
  const seen = new Set<string>();
  return items.filter((item) => {
    const key = keys.map((k) => item[k]).join('|');
    return !seen.has(key) && seen.add(key);
  });
};

export const groupBy = <T>(xs: T[], key: keyof T): Record<string, T[]> =>
  xs.reduce((rv, x) => {
    (rv[String(x[key])] ??= []).push(x);
    return rv;
  }, {} as Record<string, T[]>);

export const compare = (
  a: number | string | boolean,
  b: number | string | boolean,
  isAsc = true
): number => (a < b ? -1 : 1) * (isAsc ? 1 : -1);

export const localeCompare = (
  a: number | string,
  b: number | string,
  isAsc = true
): number => String(a).localeCompare(String(b), undefined, {numeric: true, sensitivity: 'base'}) * (isAsc ? 1 : -1);

export const compareByAlphaNumKey = <E>(a: E, b: E, key: keyof E, isAsc = true): number => {
  const aString = String(a[key] ?? '');
  const bString = String(b[key] ?? '');
  return aString.localeCompare(bString, undefined, {numeric: true, sensitivity: 'base'}) * (isAsc ? 1 : -1);
};

export const sortByArray = <T, U>(
  source: T[],
  by: U[],
  sourceTransformer: (item: T) => U = identity
): T[] => {
  const indexesByElements = new Map(by.map((item, idx) => [item, idx]))
  return sortBy(source, (item) => {
    const index = indexesByElements.get(sourceTransformer(item))
    return index === undefined ? by.length : index // Place missing elements at the end
  })
}

export const partition = <T>(array: T[], predicate: (arg0: T) => boolean): [T[], T[]] =>
  array.reduce(
    (result, e) => {
      result[predicate(e) ? 0 : 1].push(e);
      return result;
    },
    [[], []] as [T[], T[]],
  );

export const isSameContent = <T>(a: T[], b: T[], key: keyof T | (keyof T)[] = []): boolean => {
  if (a?.length !== b?.length) return false;
  const keys = toUniqueNonNullableArray(key);
  return keys.length > 0
    ? a.every(aValue => b.some(bValue =>
        keys.every(k => bValue[k] === aValue[k])
      ))
    : a.every((value) => b.includes(value));
};

export const isSameContentOrder = <T>(a: T[], b: T[], key: keyof T | (keyof T)[] = []): boolean => {
  if (a?.length !== b?.length) return false;
  const keys = toUniqueNonNullableArray(key);
  return keys.length > 0
    ? a.every((aValue, index) =>
        keys.every(k => aValue[k] === b[index][k])
      )
    : a.every((value, index) => value === b[index]);
};

export const shuffleArray = <T>(array: T[]): void => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const shuffledArray = <T>(array: T[]): T[] =>
  array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

export type NestedKeyOf<T extends object> = {
  [Key in keyof T & (string | number)]: T[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<T[Key]>}`
    : `${Key}`
}[keyof T & (string | number)];

export function getValue<T extends object>(object: T, path: NestedKeyOf<T>) {
  return path.split('.').reduce((obj, key) => Object(obj)[key], object as T);
}

export const defined = (property?: string | string[]): string | undefined => {
  if (typeof property === 'string') {
    return property !== 'N/A'
      ? property
          .replace(/{(\/?\w+)}/g, '<$1>')  // Convert {word} → <word>
          .replace(/{()}/g, '&nbsp;')      // Convert {} → &nbsp;
          .replace(/@ /g, ', ')            // Convert "@ " → ", "
          .replace(/<([^>]+)>/g, '{$1}')   // Convert <word> → {word}
      : undefined;
  }
  if (Array.isArray(property)) {
    return property.length > 0 && property[0] !== '[All]' ? property.join('|') : '';
  }
  return undefined;
};

export const randomColor = (min = 0, max = 16777215): string =>
  `#${Math.floor(Math.random() * (max - min)).toString(16).padStart(6, '0')}`;

export const stringToColor = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    colour += value.toString(16).padStart(2, '0');
  }
  return colour;
};

export const normalizeStat = (value: number | string, type?: 'number' | 'duration'): string => {
  switch(type) {
    case 'number': return numberFormatter(Number(value) || 0);
    case 'duration': return durationFormatter(Number(value) || 0);
    default: return (Number(value) || 0).toString();
  }
};

export const isOverflown = <T extends { scrollHeight: number; clientHeight: number }>(e: T): boolean =>
  e.scrollHeight > e.clientHeight;

export const isTextOverflow = (elementId: string): boolean => {
  const elem = document.getElementById(elementId);
  return !!elem && elem.offsetWidth < elem.scrollWidth;
};

interface ResizeTextParams {
  elements: NodeListOf<HTMLElement>;
  minSize?: number;
  maxSize?: number;
  step?: number;
  unit?: string;
}

export const resizeText = ({
  elements,
  minSize = 10,
  maxSize = 512,
  step = 1,
  unit = 'px'
}: ResizeTextParams): void => {
  elements.forEach(el => {
    let i = minSize;
    let overflow = false;
    const parent = el.parentElement;

    while (!overflow && i < maxSize) {
      el.style.fontSize = `${i}${unit}`;
      overflow = parent ? isOverflown(parent) : false;
      if (!overflow) i += step;
    }

    el.style.fontSize = `${i - step}${unit}`;
  });
};

export const countTitle = (items: unknown[], singular: string, plural: string, spacer = NO_BREAK_SPACE): string =>
  `${items.length}${spacer}${items.length > 1 ? plural : singular}`;

export const getRandomInt = (min: number, max: number): number =>
  Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);

export type RGB = [number, number, number];

export const contrast = (foregroundColor: RGB, backgroundColor: RGB): number => {
  const foregroundLuminance = luminance(foregroundColor);
  const backgroundLuminance = luminance(backgroundColor);
  return backgroundLuminance < foregroundLuminance
    ? ((backgroundLuminance + 0.05) / (foregroundLuminance + 0.05))
    : ((foregroundLuminance + 0.05) / (backgroundLuminance + 0.05))
  ;
};

export const luminance = (rgb: RGB): number => {
  const [r, g, b] = rgb.map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return r * 0.2126 + g * 0.7152 + b * 0.0722;
};

export const getRgbColorFromHex = (hex: string): RGB => {
  const value = parseInt(hex.slice(1), 16);
  const r = (value >> 16) & 255;
  const g = (value >> 8) & 255;
  const b = value & 255;
  return [r, g, b];
};

export const getGreetings = (): string => {
  const now = datelib().hour()
  const found = now > 11 ? (now > 16 ? 2 : 1) : 0;
  const greetings = ['morning', 'afternoon', 'evening'];
  return `Good ${greetings[found]}`;
};

export const numberFormatter = (num: number, digits = 0, sub = true): string => {
  const _tier = num ? Math.floor(Math.log10(Math.abs(num)) / 3) : 0;
  const value = +(num / Math.pow(10, _tier * 3));
  const units = _tier > 0 ? ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'] : ['', 'm', 'µ', 'n', 'p', 'f', 'a', 'z', 'y'];
  const tier = Math.abs(_tier) < units.length ? Math.abs(_tier) : 0;
  return (_tier >= 0 || sub) ? `${value.toFixed(value > 1 ? digits : 0)}${units[tier]}` : '';
};

export const durationFormatter = (time = 0, short = true, single = true): string => {
  const duration = datelib.duration(time, 's')

  const hours = duration.asHours()
  const minutes = duration.asMinutes()
  const seconds = duration.asSeconds()

  const hLabel = short ? 'h' : `${NO_BREAK_SPACE}hour${Math.round(hours) !== 1 ? 's' : ''}`
  const mLabel = short ? 'm' : `${NO_BREAK_SPACE}min${Math.round(minutes) !== 1 ? 's' : ''}`
  const sLabel = short ? 's' : `${NO_BREAK_SPACE}second${Math.round(seconds) !== 1 ? 's' : ''}`

  let result = ''

  if (hours >= 1) {
    result = `${Math.round(hours)}${hLabel}`
    if (!single && duration.minutes() > 0) {
      result += ` ${Math.round(duration.minutes())}${mLabel}`
    }
  } else if (minutes >= 1) {
    result = `${Math.round(minutes)}${mLabel}`
    if (!single && duration.seconds() > 0) {
      result += ` ${Math.round(seconds % 60)}${sLabel}`
    }
  } else {
    result = `${Math.round(seconds)}${sLabel}`
  }

  return result || `0${sLabel}`
}

export const dateFormatter = (date: Date | Timestamp, short = true, single = true): string => {
  // Use DateOnly for consistent date-only comparisons
  const now = new DateOnly();
  const dateObj = date instanceof Timestamp ? new DateOnly(date) : new DateOnly(date);

  // Calculate day difference using DateOnly's methods
  const daysDiff = DateOnly.diff(now, dateObj, 'day');
  const absDaysDiff = Math.abs(daysDiff);

  // Calculate other time units from the original dates
  const nowDate = now.toDate();
  const dateDate = dateObj.toDate();
  const diffMs = Math.abs(dateDate.getTime() - nowDate.getTime());

  const msPerSecond = 1000;
  const msPerMinute = msPerSecond * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30.436875; // Average days per month
  const msPerYear = msPerDay * 365.25; // Account for leap years

  const years = Math.floor(diffMs / msPerYear);
  const months = Math.floor(diffMs / msPerMonth);
  const hours = Math.floor((diffMs % msPerDay) / msPerHour);
  const minutes = Math.floor((diffMs % msPerHour) / msPerMinute);
  const seconds = Math.floor((diffMs % msPerMinute) / msPerSecond);

  // Define labels based on the short parameter
  const yLabel = short ? 'y' : `${NO_BREAK_SPACE}year${years !== 1 ? 's' : ''}`;
  const moLabel = short ? 'mo' : `${NO_BREAK_SPACE}month${months !== 1 ? 's' : ''}`;
  const dLabel = short ? 'd' : `${NO_BREAK_SPACE}day${absDaysDiff !== 1 ? 's' : ''}`;
  const hLabel = short ? 'h' : `${NO_BREAK_SPACE}hour${hours !== 1 ? 's' : ''}`;
  const mLabel = short ? 'm' : `${NO_BREAK_SPACE}min${minutes !== 1 ? 's' : ''}`;
  const sLabel = short ? 's' : `${NO_BREAK_SPACE}second${seconds !== 1 ? 's' : ''}`;

  let result = '';

  if (years >= 1) {
    result = `${years}${yLabel}`;
    if (!single && months % 12 > 0) {
      result += ` ${months % 12}${moLabel}`;
    }
  } else if (months >= 1) {
    result = `${months}${moLabel}`;
    if (!single && absDaysDiff % 30 > 0) {
      result += ` ${absDaysDiff % 30}${dLabel}`;
    }
  } else if (absDaysDiff >= 1) {
    result = `${absDaysDiff}${dLabel}`;
    if (!single && hours > 0) {
      result += ` ${hours}${hLabel}`;
    }
  } else if (hours >= 1) {
    result = `${hours}${hLabel}`;
    if (!single && minutes > 0) {
      result += ` ${minutes}${mLabel}`;
    }
  } else if (minutes >= 1) {
    result = `${minutes}${mLabel}`;
    if (!single && seconds > 0) {
      result += ` ${seconds}${sLabel}`;
    }
  } else {
    result = `${seconds}${sLabel}`;
  }

  return result || `0${sLabel}`;
};

// Extension for String prototype
declare global {
  interface String {
    toTitleCase(): string;
  }
};

String.prototype.toTitleCase = function(): string {
  return this.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

// Extensions for Array prototype
declare global {
  interface Array<T> {
    equals(array: T[]): boolean;
    rotate(count: number): T[];
    shuffle(): T[];
    shuffled(): T[];
  }
}

Array.prototype.equals = function<T>(array: T[]): boolean {
  if (!array) return false;
  if (this.length !== array.length) return false;

  for (let i = 0, l = this.length; i < l; i++) {
    if (this[i] instanceof Array && array[i] instanceof Array) {
      if (!(this[i] as unknown as Array<T>).equals(array[i] as unknown as Array<T>)) return false;
    } else if (this[i] !== array[i]) {
      return false;
    }
  }
  return true;
};

Array.prototype.rotate = function<T>(count: number): T[] {
  const len = this.length >>> 0;
  count = count >> 0;
  return [...this.slice(count % len), ...this.slice(0, count % len)];
};

Array.prototype.shuffle = function<T>(): T[] {
  for (let i = this.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [this[i], this[j]] = [this[j], this[i]];
  }
  return this;
};

Array.prototype.shuffled = function<T>(): T[] {
  return this.slice().shuffle();
};

// Hide methods from for-in loops
Object.defineProperty(Array.prototype, 'equals', { enumerable: false });
Object.defineProperty(Array.prototype, 'rotate', { enumerable: false });
Object.defineProperty(Array.prototype, 'shuffle', { enumerable: false });
Object.defineProperty(Array.prototype, 'shuffled', { enumerable: false });


export function resolveProperty(property: unknown): boolean {
  return typeof property === 'function' ? property() : !!property;
}

export function upsert<T>(array: T[], newItem: T, key: keyof T): T[] {
  const map = new Map<T[keyof T], T>();

  // Populate the map with existing items
  array.forEach(item => map.set(item[key], item));

  // Insert or update the new item
  map.set(newItem[key], newItem);

  // Return a new array with updated values
  return Array.from(map.values());
}

export function toggleItem<T>(array: T[], item: T, key: keyof T): T[] {
  const itemKey = item[key];

  // Check if the item already exists in the array
  const exists = array.some(existingItem => existingItem[key] === itemKey);

  if (exists) {
      // Remove the item if it exists
      return array.filter(existingItem => existingItem[key] !== itemKey);
  } else {
      // Add the item if it doesn't exist
      return [...array, item];
  }
}

/**
 * Checks if the user's browser agent contains a specific keyword.
 * @param keyword - The keyword to search for in the user agent string.
 * @returns `true` if the keyword is found in the user agent, otherwise `false`.
 */
export function agentHas(keyword: string): boolean {
  return navigator.userAgent.toLowerCase().includes(keyword.toLowerCase());
}

/**
 * Detects if the user is on iOS Safari (excluding Chrome on iOS).
 * iOS Safari is the only iOS browser that supports push notifications in a PWA (iOS 16.4+).
 *
 * @returns `true` if the browser is iOS Safari, otherwise `false`.
 */
export function isIOSSafari(): boolean {
  const isIOS = agentHas('iPad') || agentHas('iPhone');  // Check if the device is iOS
  const isWebkit = agentHas('WebKit');                   // Ensure it's WebKit-based
  const isNotChrome = !agentHas('CriOS');                // Exclude Chrome on iOS

  return isIOS && isWebkit && isNotChrome;
}

/**
 * Detects if the browser is Safari (MacOS or iOS).
 * Safari is identified by unique window properties (`ApplePaySetupFeature` or `safari`)
 * and the absence of Chrome-specific identifiers (`Chrome` or `CriOS`).
 *
 * @returns `true` if the browser is Safari, otherwise `false`.
 */
export function isSafari(): boolean {
  return (
    (!!('ApplePaySetupFeature' in window) || !!('safari' in window)) &&
    agentHas("Safari") &&
    !agentHas("Chrome") &&
    !agentHas("CriOS")
  );
}
